<template>
    <div class="contact">
        <h1>// LET'S TALK</h1>
        <div class="contact-container">
            <ul>
                <li class='slide-in-tl'><i class="fas fa-envelope-square fa-2x"></i> <span>
                    spencer.haugh@gmail.com
                    </span></li>
                <li class='slide-in-br'><i class="fab fa-linkedin fa-2x"></i> <span><a href="https://linkedin.com/in/spencerhaugh" target="_blank">linkedin.com/in/spencerhaugh</a></span></li>
                <li class='slide-in-bl'><i class="fab fa-github-square fa-2x"></i> <span><a href="https://github.com/spencerhaugh" target="_blank">github.com/spencerhaugh</a></span></li>
            </ul>
        </div>
        <Resume />
    </div>
</template>

<script>
import Resume from '../components/Resume.vue';

export default {
  components: { Resume },
  name: 'Contact',
};
</script>

<style scoped>
    .contact-container {
        font-size: 1.25rem;
        padding-right: 1rem;
        overflow: hidden;
    }
    ul {
        list-style: none;
        width: 25%;
        margin: 6% auto;
        padding: 0;
    }
    li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 5px;
    }
    li i {
        transition: color 0.5s ease-in-out;
    }
    span {
        color: #f5af19;
    }
    li:hover i {
        color: #f5af19;
        transition: 0.5s ease-in-out;
        transform: translateX(5px);
    }
    ul li.slide-in-tl {
    -webkit-animation: slide-in-tl 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-tl 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    ul li.slide-in-br {
    -webkit-animation: slide-in-br 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-br 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    ul li.slide-in-bl {
    -webkit-animation: slide-in-bl 1.75s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-bl 1.75s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-9-6 11:53:30
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-tl
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-tl {
  0% {
    -webkit-transform: translateY(-1000px) translateX(-1000px);
            transform: translateY(-1000px) translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-tl {
  0% {
    -webkit-transform: translateY(-1000px) translateX(-1000px);
            transform: translateY(-1000px) translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    opacity: 1;
  }
}
@-webkit-keyframes slide-in-bl {
  0% {
    -webkit-transform: translateY(1000px) translateX(-1000px);
            transform: translateY(1000px) translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-bl {
  0% {
    -webkit-transform: translateY(1000px) translateX(-1000px);
            transform: translateY(1000px) translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    opacity: 1;
  }
}
    @-webkit-keyframes slide-in-br {
  0% {
    -webkit-transform: translateY(1000px) translateX(1000px);
            transform: translateY(1000px) translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-br {
  0% {
    -webkit-transform: translateY(1000px) translateX(1000px);
            transform: translateY(1000px) translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    opacity: 1;
  }
}
@media (max-width: 1224px) {
        ul {
            width: 40%;
        }
    @media (max-width: 768px) {
        .contact-container {
            font-size: 0.75rem;
        }
        ul {
            width: 50%;
            margin: 0 auto;
        }
        ul li {
            font-size: 1rem;
        }
    }
    @media (max-width: 515px) {
        ul {
            width: 90%;
            margin: 10% auto;
        }
    }
    }

</style>
